import Vue from 'vue'
import VueRouter from 'vue-router'
import {getMerchId} from '../api/requist'

const Index = () => import('../views/index');
const listIndex = () => import('../views/listIndex');
const serviceDetail = () => import('../views/serviceDetail');
const serviceList = () => import('../views/serviceList');
const listDetail = () => import('../views/listDetail');

const tackRest = () => import('../views/tackRest');
const problem = () => import('../views/problem');
const myWallet = () => import('../views/myWallet');
const dataCenter = () => import('../views/dataCenter');
const storeSettled = () => import('../views/storeSettled');
const shopSet = () => import('../views/shopSet');
const myCard = () => import('../views/myCard');

const cashOut = () => import('../views/cashOut');
const addCard = () => import('../views/addCard');
const cashResult = () => import('../views/cashResult');
const settledResult = () => import('../views/settledResult');
const authorize = () => import('../views/authorize');
const shareShop = () => import('../views/shareShop');
const management = () => import('../views/management');
const commodityOperate = () => import('../views/commodityOperate');
const searchProduct = () => import('../views/searchProduct');
const importProduct = () => import('../views/importProduct');
const importResult = () => import('../views/importResult');
const setImportList = () => import('../views/setImportList');
const handleMenu = () => import('../views/handleMenu');
const sellOut = () => import('../views/sellOut');
const examine = () => import('../views/examine');
const orders = () => import('../views/orders');
const mine = () => import('../views/mine');
const codesCollector = () => import('../views/codesCollector');
const shopSelector = () => import('../views/shopSelector');
const addGoods = () => import('../views/addGoods');

Vue.use(VueRouter);

const routes = [
    {
        path: '/codesCollector',
        name: 'codesCollector',
        component: codesCollector,
        meta: {title: '条码采集器', keepAlive: false}
    }, {
        path: '/shopSelector',
        name: 'shopSelector',
        component: shopSelector,
        meta: {title: '选择店铺', keepAlive: false}
    }, {
        path: '/addGoods',
        name: 'addGoods',
        component: addGoods,
        meta: {title: '添加商品', keepAlive: false}
    }, {
        path: '/examine',
        name: 'examine',
        component: examine,
        meta: {title: '审核', keepAlive: false}
    }, {
        path: '/sellOut',
        name: 'sellOut',
        component: sellOut,
        meta: {title: '售罄', keepAlive: false}
    }, {
        path: '/handleMenu',
        name: 'handleMenu',
        component: handleMenu,
        meta: {title: '菜单', keepAlive: false}
    }, {
        path: '/setImportList',
        name: 'setImportList',
        component: setImportList,
        meta: {title: '批量导入商品库', keepAlive: false}
    },
    {
        path: '/importProduct',
        name: 'importProduct',
        component: importProduct,
        meta: {title: '导入商品', keepAlive: false}
    }, {
        path: '/importResult',
        name: 'importResult',
        component: importResult,
        meta: {title: '导入结果', keepAlive: false}
    },
    {
        path: '/management',
        name: 'management',
        component: management,
        meta: {title: '商品管理', keepAlive: true}
    },

    {
        path: '/searchProduct',
        name: 'searchProduct',
        component: searchProduct,
        meta: {title: '搜索商品', keepAlive: false}
    },
    {
        path: '/commodityOperate',
        name: 'commodityOperate',
        component: commodityOperate,
        meta: {title: '商品详情', keepAlive: false}
    },
    {
        path: '/',
        redirect: '/authorize',
        meta: {title: '授权验证', keepAlive: false}
    },
    {
        path: '/index',
        name: 'index',
        component: Index,
        redirect: '/orders',
        children: [
            {
                path: '/orders',
                name: 'orders',
                component: orders,
                meta: {title: '东熙小店', keepAlive: true}
            },
            // 旧版本订单列表页面
            {
                path: '/listIndex',
                name: 'listIndex',
                component: listIndex,
                meta: {title: '东熙小店', keepAlive: false}
            },
            {
                path: '/dataCenter',
                name: 'dataCenter',
                component: dataCenter,
                meta: {title: '东熙小店', keepAlive: false}
            },
            {
                path: '/mine',
                name: 'mine',
                component: mine,
                meta: {title: '东熙小店', keepAlive: false}
            }
        ]
    },

    {
        path: '/listDetail',
        name: 'listDetail',
        component: listDetail,
        meta: {title: '订单详情', keepAlive: false}
    },
    {
        path: '/serviceList',
        name: 'serviceList',
        component: serviceList,
        meta: {title: '订单列表', keepAlive: false}
    },
    {
        path: '/serviceDetail',
        name: 'serviceDetail',
        component: serviceDetail,
        meta: {title: '售后详情', keepAlive: false}
    },
    {
        path: '/tackRest',
        name: 'tackRest',
        component: tackRest,
        meta: {title: '歇业休息', keepAlive: false}
    },
    {
        path: '/problem',
        name: 'problem',
        component: problem,
        meta: {title: '问题反馈', keepAlive: false}
    },
    {
        path: '/myWallet',
        name: 'myWallet',
        component: myWallet,
        meta: {title: '我的钱包', keepAlive: false}
    },
    {
        path: '/storeSettled',
        name: 'storeSettled',
        component: storeSettled,
        meta: {title: '东熙小店入驻申请', keepAlive: false}
    },
    {
        path: '/shopSet',
        name: 'shopSet',
        component: shopSet,
        meta: {title: '店铺设置', keepAlive: false}
    },
    {
        path: '/myCard',
        name: 'myCard',
        component: myCard,
        meta: {title: '我的银行卡', keepAlive: false}
    },
    {
        path: '/addCard',
        name: 'addCard',
        component: addCard,
        meta: {title: '添加银行卡', keepAlive: false}
    },
    {
        path: '/cashOut',
        name: 'cashOut',
        component: cashOut,
        meta: {title: '提现', keepAlive: false}
    },
    {
        path: '/cashResult',
        name: 'cashResult',
        component: cashResult,
        meta: {title: '提现结果', keepAlive: false}
    },
    {
        path: '/settledResult',
        name: 'settledResult',
        component: settledResult,
        meta: {title: '申请结果', keepAlive: false}
    },

    {
        path: '/authorize',
        name: 'authorize',
        component: authorize,
        meta: {title: '授权登录', keepAlive: false}
    },
    {
        path: '/shareShop',
        name: 'shareShop',
        component: shareShop,
        meta: {title: '分享店铺', keepAlive: false}
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


function getCode(fn) {
    // 非静默授权，第一次有弹框
    let code = getUrlCode().code; // 截取code
    const local = window.location.href; // 获取页面url
    const appId = this.$root.wxAppId;
    if (code == null || code === "") {
        // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
    } else {
        return getMerchIdFn(code, fn)
    }
}


function getUrlCode() {
    // 截取url中的code方法
    const url = location.search;
    const theRequest = {};
    if (url.indexOf("?") !== -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
    }
    return theRequest;
}

function getMerchIdFn(code, fn) {
    let formData = new FormData();
    formData.append("code", code);
    return getMerchId(formData).then((res) => {
        if (res.code === 200) {
            // alert(res.data.id + '返回的id')
            localStorage.setItem('merch_id', res.data.id);
            return fn()
        } else if (res.code === 202) { // 店铺不存在
            return router.push({
                path: '/storeSettled'
            })
        }

    }).catch(() => {
        fn();
        return false
    })
}

router.beforeEach((to, from, next) => {
    // TODO 测试环境使用merch_id，发布前注释
    // localStorage.setItem('merch_id', 'MUZscHZmNzBDc2FrVmpxZ2VXVVBYUXhTeUppem0%3D');
    if (from.path === '/') {
        if (to.path !== '/authorize' && to.path !== '/orders') {
            sessionStorage.removeItem('firstPage')
        }
    }

    if (!sessionStorage.getItem('firstPage')) {
        if (to.path === '/authorize') {
            sessionStorage.setItem('firstPage', window.location.href.split('#')[0] + 'orders')
        } else {
            sessionStorage.setItem('firstPage', window.location.href.split('#')[0])
        }
    }

    if (to.path === '/storeSettled' || to.path === '/authorize' || to.path === '/settledResult'
        // 运营工具：条码采集器，不需要验证身份
        || to.path === '/codesCollector'
        // 运营工具：商户商品添加工具，不需要验证身份
        || to.path === '/shopSelector'
        || to.path === '/addGoods'
    ) {
        next()
    } else {
        if (localStorage.getItem('merch_id') === null) {
            getCode(function () {
                next();
            })
        } else {
            next();
        }
    }

    document.title = to.meta.title;
});


router.afterEach(() => {
    window.scrollTo(0, 0)
});


export default router
