import {post} from './http.js'

// 获取商户列表
export const getSingleGoodsInfo = parmas => post('/index.php/merch/Product/actionProductInfoApi', parmas);

// 获取商户列表
export const submitBarCodeToAddGoodsForShop = parmas => post('/index.php/merch/ProductAtlas/actionProductAdd', parmas);

// 获取商户列表
export const getShops = parmas => post('/index.php/merch/ProductAtlas/actionMerchList', parmas);

// 扫码采集器，提交录入条形码
export const submitBarCode = parmas => post('/index.php/merch/ProductAtlas/actionProductAtlasSaveApi', parmas);

// 扫码采集器，获取商品列表
export const codesCollectorGoodsList = parmas => post('/index.php/merch/ProductAtlas/actionProductAtlasListApi/', parmas);

export const ordersList = parmas => post('/index.php/merch/Orders/actionOrdersListApi/', parmas);

export const ordersDetail = parmas => post('/index.php/merch/Orders/actionOrdersDetailsApi/', parmas);

export const myBankCard = parmas => post('/index.php/merch/Bank/actionMyBankListApi/', parmas);

export const removeBankCard = parmas => post('/index.php/merch/Bank/actionMyBankDelApi', parmas);

export const allBank = parmas => post('/index.php/merch/Bank/actionBankListApi', parmas);

export const addBank = parmas => post('/index.php/merch/Bank/actionMyBankAddApi', parmas);

export const refundList = parmas => post('/index.php/merch/Orders/actionRefundOrdersListApi', parmas);

export const refundDetail = parmas => post('/index.php/merch/Orders/actionRefundOrdersDetailsApi', parmas);

export const refundOperate = parmas => post('/index.php/merch/Orders/actionVerifyRefundApi', parmas);

export const shopRest = parmas => post('/index.php/merch/MerchInfo/actionBusinessApi', parmas);

export const userInfo = parmas => post('/index.php/merch/MerchInfo/actionMerchinfoApi', parmas);

export const feedbackProblem = parmas => post('/index.php/merch/MerchInfo/actionFeedbackApi', parmas);

export const shopInfoEdit = parmas => post('/index.php/merch/MerchInfo/actionMerchSaveApi', parmas);

export const handleOrder = parmas => post('/index.php/merch/Orders/actionOrderStatusApi', parmas);

export const printTicket = parmas => post('/index.php/merch/Orders/actionPrintTicketApi', parmas);

export const handleShortage = parmas => post('/index.php/merch/Orders/actionProductStatusApi', parmas);

export const dataCenter = parmas => post('/index.php/merch/DataCenter/actionDataCenterApi', parmas);

export const walletInfo = parmas => post('/index.php/merch/DataCenter/actionMyWalletApi', parmas);

export const cashOutInfo = parmas => post('/index.php/merch/DataCenter/actionWithdrawalLocationApi', parmas);

export const submitCashOut = parmas => post('/index.php/merch/DataCenter/actionConfirmedWithdrawalApi', parmas);

export const getMerchId = parmas => post('/index.php/merch/Login/actionMerchInfoApi', parmas);

export const getStordId = parmas => post('/index.php/merch/Settled/getMerchInfoApi', parmas);

export const storeSettled = parmas => post('/index.php/merch/Settled/actionSettledAdd', parmas);

export const shareInfo = parmas => post('/index.php/merch/MerchInfo/actionMerchApi', parmas);

export const initGoodsList = parmas => post('/index.php/merch/ProductCategory/actionProductCategoryApi', parmas);

export const firstMenuApi = parmas => post('/index.php/merch/ProductCategory/actionSubsetListApi', parmas);

export const secondMenuApi = parmas => post('/index.php/merch/ProductCategory/actionProductListApi', parmas);

export const productDetails = parmas => post('/index.php/merch/ProductCategory/actionProductInfoApi', parmas);

export const productDismount = parmas => post('/index.php/merch/ProductCategory/actionProductRecycle', parmas); //下架

export const productSellOut = parmas => post('/index.php/merch/ProductCategory/actionProductStatus', parmas); //下架

export const productDelete = parmas => post('/index.php/merch/ProductCategory/actionProductDel', parmas); //删除

export const productHandle = parmas => post('/index.php/merch/ProductCategory/actionProductEditApi', parmas);

export const historyList = parmas => post('/index.php/merch/ProductCategory/actionMerchSearchHistoryListApi', parmas);

export const historySave = parmas => post('/index.php/merch/ProductCategory/actionSearchSaveApi', parmas);

export const historyDelete = parmas => post('/index.php/merch/ProductCategory/actionMerchSearchHistoryDelApi', parmas);

export const historyInfoList = parmas => post('/index.php/merch/ProductCategory/actionProductList', parmas);

export const productClassify = parmas => post('/index.php/merch/ProductCategory/actionProductCategoryListApi', parmas);
export const getUnitList= parmas => post('/index.php/merch/Product/actionUnitListApi', parmas);

export const handleSpecialProduct = parmas => post('/index.php/merch/ProductCategory/actionProductisSpecialPriceApi', parmas);

export const handleActiveProduct = parmas => post('/index.php/merch/ProductCategory/actionProductRecommendApi', parmas);

//导入模块
export const initImportList = parmas => post('/index.php/merch/ProductImport/actionProductCategoryApi', parmas);

export const secondImportApi = parmas => post('/index.php/merch/ProductImport/actionProductListApi', parmas);

export const firstImportApi = parmas => post('/index.php/merch/ProductImport/actionSubsetListApi', parmas);

export const importSubmit = parmas => post('/index.php/merch/ProductImport/actionProductImportApi', parmas);

export const importSearchList = parmas => post('/index.php/merch/ProductImport/actionProductAtlasListApi', parmas);

export const submitEdit = parmas => post('/index.php/merch/ProductImport/actionProductSaveApi', parmas);

export const importEditList = parmas => post('/index.php/merch/ProductImport/actionProductListsApi', parmas);

export const sellOutList = parmas => post('/index.php/merch/Product/actionProductListApi', parmas);

export const delProduct = parmas => post('/index.php/merch/Product/actionProductDelApi', parmas);

export const listNum = parmas => post('/index.php/merch/Product/actionProductMenuApi', parmas);


