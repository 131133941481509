import Vue from 'vue'
import App from './App.vue'
import './common/js/rem.js'
import router from './router'
import store from './store'
import './common/css/public.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import echarts from 'echarts'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueLazyload from 'vue-lazyload'
import 'swiper/css/swiper.css'

Vue.prototype.$echarts = echarts;
Vue.use(Vant);

// 使用VueLazyLoad并传入相应的配置
Vue.use(VueLazyload, {
    error: require('./assets/images/loader.png'),
    loading: require('./assets/images/loader.png'),// 懒加载使用的图片
});

Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false;
new Vue({
    data() {
        return {
            // wxAppId: 'wxe6a28ec514f3738f' // 测试环境
            wxAppId: 'wx64b2c3ffa2a62175' // 正式环境
        }
    },
    router,
    store,
    render: h => h(App)
}).$mount('#app');
