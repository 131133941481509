// 屏幕适配代码
function init() {

    var width = document.documentElement.clientWidth
        || document.body.clientWidth || window.innerWidth;
    // 分100份
    var fontSize = width / 100;
    document.documentElement.style.fontSize = fontSize + 'px';
}

init();
window.addEventListener('orientationchange', init);
window.addEventListener('resize', init);
